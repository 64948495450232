import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Terms from "./style"
export default function TermsCondition() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-12 col-lg-11 col-xs-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Aviso de Privacidad</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.Text>
                CITY TOWERS PEDREGAL SA DE CV., mejor conocido como PARK
                PEDREGAL, con domicilio en calle Blvd. Adolfo Ruíz Cortines
                3580, colonia San Jerónimo Aculco, Ciudad de México, delegación
                La Magdalena Contreras, CP 10400, en la Ciudad de México, y
                portal de internet www.parkpedregal.com , es el responsable del
                uso y protección de sus datos personales, y al respecto le
                informamos lo siguiente:
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  ¿Para qué fines utilizaremos sus datos personales?
                </Terms.TitleSmall>
                Los datos personales que recabamos de usted, los utilizaremos
                para las siguientes finalidades que son necesarias para el
                servicio que solicita: • PARA VERIFICAR Y CONFIRMAR SU IDENTIDAD
                • PARA COMPLETAR EXPEDIENTE PARA TRÁMITE DE CRÉDITO HIPOTECARIO
                • PARA COMPLETAR EXPEDIENTE PARA LA ESCRITURACIÓN DEL BIEN
                INMUEBLE • NOTIFICACIONES de manera adicional, utilizaremos su
                información personal para las siguientes finalidades secundarias
                que no son necesarias para el servicio solicitado, pero que nos
                permiten y facilitan brindarle una mejor atención: •
                Mercadotecnia o publicitaria • Prospección comercial En caso de
                que no desee que sus datos personales sean tratados para estos
                fines secundarios, desde este momento usted nos puede comunicar
                lo anterior a través del siguiente mecanismo: A través de
                www.parkpedregal.com o por llamada telefónica (55) 56040565. La
                negativa para el uso de sus datos personales para estas
                finalidades no podrá ser un motivo para que le neguemos los
                servicios y productos que solicita o contrata con nosotros.
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  ¿Qué datos personales utilizaremos para estos fines?
                </Terms.TitleSmall>
                Para llevar a cabo las finalidades descritas en el presente
                aviso de privacidad, utilizaremos los siguientes datos
                personales:
                <ul>
                  <li>Nombre</li>
                  <li>Estado Civil</li>
                  <li>Registro Federal de Contribuyentes(RFC)</li>
                  <li>Clave única de Registro de Población (CURP)</li>
                  <li> Lugar de nacimiento </li>
                  <li>Fecha de nacimiento</li>
                  <li>Nacionalidad</li>
                  <li>Domicilio</li>
                  <li>Teléfono particular</li>
                  <li>Teléfono celular</li>
                  <li>Correo electrónico</li>
                  <li>Firma autógrafa</li>
                  <li>Edad</li>
                  <li>Puesto o cargo que desempeña</li>
                  <li>Domicilio de trabajo</li>
                  <li>Correo electrónico institucional</li>
                  <li>Teléfono institucional</li>
                  <li> Referencias laborales</li>
                  <li>Historial crediticio</li>
                  <li>Ingresos</li>
                  <li>Egresos</li>
                  <li>Pasatiempos</li>
                  <li>Aficiones</li>
                  <li>Deportes que practica</li>
                </ul>
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  ¿Con quién compartimos su información personal y para qué
                  fines?
                </Terms.TitleSmall>
                Le informamos que sus datos personales son compartidos dentro
                del país con las siguientes personas, empresas, organizaciones o
                autoridades distintas a nosotros, para los siguientes fines:
                <ul>
                  <li>
                    {" "}
                    Destinatario de datos personales INSTITUCIONES FINANCIERAS,
                    NOTARÍAS
                  </li>
                  <li>
                    {" "}
                    Finalidad Trámite de crédito hipotecario, Escrituración de
                    bienes inmuebles{" "}
                  </li>
                  <li> Requiere del consentimiento NO</li>
                </ul>
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  ¿Cómo puede acceder, rectificar o cancelar sus datos
                  personales, u oponerse a su uso?
                </Terms.TitleSmall>
                Usted tiene derecho a conocer qué datos personales tenemos de
                usted, para qué los utilizamos y las condiciones del uso que les
                damos (Acceso). Asimismo, es su derecho solicitar la corrección
                de su información personal en caso de que esté desactualizada,
                sea inexacta o incompleta (Rectificación); que la eliminemos de
                nuestros registros o bases de datos cuando considere que la
                misma no está siendo utilizada adecuadamente (Cancelación); así
                como oponerse al uso de sus datos personales para fines
                específicos (Oposición). Estos derechos se conocen como derechos
                ARCO. Para el ejercicio de cualquiera de los derechos ARCO,
                usted deberá presentar la solicitud respectiva a través del
                siguiente medio: Enviando un correo electrónico a
                info@parkpedregal.com . Para conocer el procedimiento y
                requisitos para el ejercicio de los derechos ARCO, ponemos a su
                disposición el siguiente medio: Enviando un correo electrónico a
                info@parkpedregal.com . Los datos de contacto de la persona o
                departamento de datos personales que está a cargo de dar trámite
                a las solicitudes de derechos ARCO, son los siguientes: a)
                Gerencia de Comercialización. b) Domicilio: Cerrada Oaxaca 15,
                San Jerónimo Aculco,, 03400 Ciudad de México, Mexico c) Correo
                electrónico info@parkpedregal.com d) Número telefónico: (55)
                56040565
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  Usted puede revocar su consentimiento para el uso de sus datos
                  personales
                </Terms.TitleSmall>
                Usted puede revocar el consentimiento que, en su caso, nos haya
                otorgado para el tratamiento de sus datos personales. Sin
                embargo, es importante que tenga en cuenta que no en todos los
                casos podremos atender su solicitud o concluir el uso de forma
                inmediata, ya que es posible que por alguna obligación legal
                requiramos seguir tratando sus datos personales. Asimismo, usted
                deberá considerar que para ciertos fines, la revocación de su
                consentimiento implicará que no le podamos seguir prestando el
                servicio que nos solicitó, o la conclusión de su relación con
                nosotros. Para revocar su consentimiento deberá presentar su
                solicitud a través del siguiente medio: Enviando un correo
                electrónico a info@parkpedregal.com Para conocer el
                procedimiento y requisitos para la revocación del
                consentimiento, ponemos a su disposición el siguiente medio:
                Enviando un correo electrónico a info@parkpedregal.com.
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  ¿Cómo puede limitar el uso o divulgación de su información
                  personal?
                </Terms.TitleSmall>
                Con objeto de que usted pueda limitar el uso y divulgación de su
                información personal, le ofrecemos los siguientes medios:
                Llamando al (55) 56040565 o enviando un correo electrónico
                a info@parkpedregal.mx. De manera adicional, le informamos que
                contamos con los siguientes listados de exclusión, en los cuales
                podrá registrarse para que sus datos personales no sean tratados
                para ciertos fines:
                <ul>
                  <li>Nombre del listado: LISTA PRIVADA</li>
                  <li>
                    {" "}
                    Finalidad para las que aplica: Sólo comunicados, no
                    publicidad
                  </li>
                  <li>
                    Medio para obtener mayor información: Llamando 55 1320 9258
                    o enviando un correo electrónico a info@parkpedregal.mx.
                  </li>
                </ul>
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  El uso de tecnologías de rastreo en nuestro portal de internet
                </Terms.TitleSmall>
                Le informamos que en nuestra página de internet utilizamos
                cookies, web beacons u otras tecnologías, a través de las cuales
                es posible monitorear su comportamiento como usuario de
                internet, así como brindarle un mejor servicio y experiencia al
                navegar en nuestra página. Los datos personales que recabamos a
                través de estas tecnologías, los utilizaremos para los
                siguientes fines: Conocer el perfil de nuestros compradores y
                estadísticas de publicidad o de mercado Los datos personales que
                obtenemos de estas tecnologías de rastreo son los siguientes:
                <ul>
                  <li>Región en la que se encuentra el usuario</li>
                  <li>Tipo de navegador del usuario</li>
                  <li>Tipo de sistema operativo del usuario</li>
                  <li>Fecha y hora del inicio y final de sesión de usuario</li>
                  <li>Hábitos de consumo en páginas de compras</li>
                  <li> Páginas web visitadas por un usuario</li>
                  <li> Búsquedas realizadas por un usuario </li>
                  <li> Publicidad revisada por un usuario</li>
                  <li>Listas</li>
                </ul>
                <br />
                <br />
                <Terms.TitleSmall as="h5">
                  ¿Cómo puede conocer los cambios en este aviso de privacidad?
                </Terms.TitleSmall>
                El presente aviso de privacidad puede sufrir modificaciones,
                cambios o actualizaciones derivadas de nuevos requerimientos
                legales; de nuestras propias necesidades por los productos o
                servicios que ofrecemos; de nuestras prácticas de privacidad; de
                cambios en nuestro modelo de negocio, o por otras causas. Nos
                comprometemos a mantenerlo informado sobre los cambios que pueda
                sufrir el presente aviso de privacidad, a través de: En el
                siguiente sitio web www.parkpedregal.com.  El procedimiento a
                través del cual se llevarán a cabo las notificaciones sobre
                cambios o actualizaciones al presente aviso de privacidad es el
                siguiente: En el siguiente sitio web  www.parkpedregal.mx.
              </Terms.Text>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
